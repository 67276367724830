<template>
  <div class="home">
    <img
      src="../assets/new-number-coming-soon.svg"
      alt="New Number logo"
      class="coming-soon">
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
  },
};
</script>

<style scss scoped>
.coming-soon {
  max-height: 50vh;
  max-width: 90vw;
}
.home {
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>